import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ListItem = ({ item }) => {
  const image = getImage(item.frontmatter.image);
  let cardClass = {};
  // Check if the image is portrait or landscape orientation
  if (image.width > image.height) {
    cardClass.image =
      "col-lg-6 d-flex justify-content-center align-items-center mb-3";
    cardClass.text = "col-lg-6 d-flex flex-column justify-content-center mb-3";
  } else {
    cardClass.image =
      "col-lg-3 d-flex justify-content-center align-items-center mb-3";
    cardClass.text = "col-lg-9 d-flex flex-column justify-content-center mb-3";
  }

  return (
    <div className="row mb-5">
      <div className={cardClass.image}>
        <GatsbyImage
          image={image}
          className="img-fluid rounded item-img"
          alt={item.frontmatter.title}
        />
      </div>

      <div className={cardClass.text}>
        <h3 className="display-6">{item.frontmatter.title}</h3>

        {item.frontmatter.datetime && (
          <p className="text-muted">
            {new Date(item.frontmatter.datetime).toLocaleString("en-GB", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZoneName: "short",
            })}
          </p>
        )}

        <p className="lead">{item.frontmatter.headline}</p>

        <div dangerouslySetInnerHTML={{ __html: item.html }} />

        <div>
          <a href={item.frontmatter.url} className="btn btn-primary">
            {item.frontmatter.buttontext}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
