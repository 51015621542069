import React, { useState, useEffect } from "react";
import "../styles/style.scss";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ListItem from "../components/ListItem";
import YearDisplay from "../components/YearDisplay";

// markup
const Publications = ({ data }) => {
  const [years, setYears] = useState([]);
  const books = data.books.nodes;
  const pageContent = data.publicationsPageContent.nodes[0];

  useEffect(() => {
    data.articles.nodes.map((article) => {
      let newYear = article.frontmatter.publicationdate;
      if (!years.includes(newYear)) {
        let shallowCopy = [...years, newYear];
        setYears(shallowCopy);
      }
      return years;
    });
  });

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <h2 className="text-center my-5 display-5">Books</h2>
      <div className="container">
        {books.map((book) => (
          <ListItem item={book} key={book.frontmatter.title} />
        ))}
      </div>

      <div className="container-fluid bg-light py-5 d-flex justify-content-center">
        <div className="content-wrapper">
          <h2 className="text-center display-5 mb-3">Articles</h2>
          <YearDisplay years={years} items={data.articles.nodes} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query publicationsPageQuery {
    publicationsPageContent: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/publications/" }
        frontmatter: { type: { eq: "page_content" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    books: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "Book" } } }
      sort: { fields: frontmatter___title, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          url
          headline
          buttontext
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        html
      }
    }
    articles: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "article" } } }
      sort: { fields: frontmatter___publicationdate, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          publicationdate(formatString: "YYYY")
        }
        html
      }
    }
  }
`;

export default Publications;
